<template>
  <div class="container">
    <PublicHeader :totalName="$t('auth').title" />
    <!-- 输入框 -->
    <div class="content">
      <div class="form">
        <!-- 国家选择 -->
        <div class="item">
          <div class="lable">{{ $t('auth').gj }}</div>
          <VueCountryIntl schema="popover" :listZIndex="10000" popoverClass="selectCountry_auth"
            :searchInputPlaceholder="$t('register').searchCountryMsg" @onChange="selectCountry"
            :disabled="ReadonlyFlag">
            <van-field v-model="Nationality" readonly :placeholder="$t('auth').tipsgj" right-icon="arrow-down"
              @click="selectCountry" slot="reference">
            </van-field>
          </VueCountryIntl>
        </div>
        <!-- 姓名 -->
        <div class="item">
          <div class="lable">{{ $t('auth').userName }}</div>
          <van-field v-model="RealName" :placeholder="$t('auth').tips1" :readonly="ReadonlyFlag">
          </van-field>
        </div>
        <!-- 证件号 -->
        <div class="item">
          <div class="lable">{{ $t('auth').IDNumber }}</div>
          <van-field v-model="Certificate" :placeholder="$t('auth').tips2" :readonly="ReadonlyFlag">
          </van-field>
        </div>
        <!-- 证件照 -->
        <div class="item">
          <div class="lable">{{ $t('auth').high }}</div>
          <div class="upImg">
            <div class="up_item">
              <div class="up_img">
                <van-uploader :after-read="justAfterRead" :disabled="ReadonlyFlag">
                  <img v-if="ObverseImage" :src="ObverseImage" width="151" height="86" />
                  <img v-else src="@/assets/img/auth_z.png" width="151" height="86" />
                </van-uploader>
              </div>
              <div class="up_name">{{ $t('auth').zmz }}</div>
            </div>
            <div class="up_item">
              <div class="up_img">
                <van-uploader :after-read="justAfterRead2" :disabled="ReadonlyFlag">
                  <img v-if="ObverseImage2" :src="ObverseImage2" width="151" height="86" />
                  <img v-else src="@/assets/img/auth_f.png" width="151" height="86" />
                </van-uploader>
              </div>
              <div class="up_name">{{ $t('auth').bmz }}</div>
            </div>
            <div class="up_item">
              <div class="up_img">
                <div class="up_img">
                  <van-uploader :after-read="justAfterRead3" :disabled="ReadonlyFlag">
                    <img v-if="ObverseImage3" :src="ObverseImage3" width="151" height="86" />
                    <img v-else src="@/assets/img/auth_s.png" width="151" height="86" />
                  </van-uploader>
                </div>
              </div>
              <div class="up_name">{{ $t('auth').scz }}</div>
            </div>
          </div>
        </div>
        <!-- 按钮 -->
        <van-button v-if="!ReadonlyFlag" color="#C92B2E" block @click="handleSub"
          style="border-radius: 5px;height: 40px;">{{ $t('auth').btn }}</van-button>
        <div class="tips" v-if="ReadonlyFlag">{{ AuditStatusMsg }}</div>
        <div class="upbtn" v-if="ReadonlyFlag" @click="handleUp">{{ $t('auth').upbtn }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import systemApi from '@/api/system'
import { mapGetters } from 'vuex'
import { getRandomString } from '@/utils/validate'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      ReadonlyFlag: false,
      RealName: '',
      Certificate: '',
      Nationality: '',
      ObverseImage: '',
      ObverseImage2: '',
      ObverseImage3: '',
      AuditStatusMsg: '',
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.getAccountBase()
  },
  methods: {
    // 获取用户信息
    async getAccountBase() {
      const res = await userApi.getAccountBase()
      const userInfo = res.Data
      if (userInfo.RealName) {
        this.RealName = userInfo.RealName
        this.Certificate = userInfo.Certificate
        this.Nationality = userInfo.Nationality
      }
      if (userInfo.Imgs) {
        this.ObverseImage = userInfo.Imgs[0]
        this.ObverseImage2 = userInfo.Imgs[1]
        this.ObverseImage3 = userInfo.Imgs[2]
      }
      this.AuditStatusMsg = userInfo.AuditStatus == 'Wait' ? this.$t('auth').wait : userInfo.AuditStatus == 'Fail' ? this.$t('auth').fail : userInfo.AuditStatus == 'Success' ? this.$t('auth').success : ''
      this.ReadonlyFlag = userInfo.AuditStatus !== 'None'
    },
    // 正面照
    async justAfterRead(file) {
      const formdata = new FormData()
      formdata.append('userfile[0]', file.file)
      this.ObverseImage = await systemApi.UploadFile(formdata)
    },
    // 背面照
    async justAfterRead2(file) {
      const formdata = new FormData()
      formdata.append('userfile[0]', file.file)
      this.ObverseImage2 = await systemApi.UploadFile(formdata)
    },
    // 手持照
    async justAfterRead3(file) {
      const formdata = new FormData()
      formdata.append('userfile[0]', file.file)
      this.ObverseImage3 = await systemApi.UploadFile(formdata)
    },
    // 选择国家
    selectCountry(val) {
      if (val.name) {
        this.Nationality = val.name
      }
      this.$nextTick(() => {
        const searchInput = document.querySelector(
          '.selectCountry_auth .search-input'
        )
        if (searchInput) {
          searchInput.style.width = '90%'
          searchInput.style.height = 'auto'
        }
      })
    },
    // 修改确认
    handleUp() {
      this.$dialog.confirm({
        title: this.$t('auth').upMsg,
        confirmButtonText: this.$t('public').confirm,
        cancelButtonText: this.$t('public').cancel,
      })
        .then(() => {
          this.ReadonlyFlag = false
        })
    },
    // 提交认证
    async handleSub() {
      if (!this.checkForm()) return
      var Imgs = []
      Imgs.push(this.ObverseImage)
      Imgs.push(this.ObverseImage2)
      Imgs.push(this.ObverseImage3)
      const form = {
        RealName: this.RealName,
        Certificate: this.Certificate,
        Nationality: this.Nationality,
        Imgs: Imgs,
      }
      await userApi.Authentication(form)
      this.getAccountBase()
      this.$toast({ message: this.$t('auth').subMsg })
      this.$router.go(-1)
    },
    checkForm() {
      if (!this.RealName) {
        this.$toast({ message: this.$t('auth').tips1 })
        return false
      }
      if (!this.Certificate) {
        this.$toast({ message: this.$t('auth').tips2 })
        return false
      }
      if (!this.Nationality) {
        this.$toast({ message: this.$t('auth').tipsgj })
        return false
      }
      // if (!this.ObverseImage) {
      //   this.$toast({ message: this.$t('auth').tips3 })
      //   return false
      // }
      // if (!this.ObverseImage2) {
      //   this.$toast({ message: this.$t('auth').tips4 })
      //   return false
      // }
      // if (!this.ObverseImage3) {
      //   this.$toast({ message: this.$t('auth').tips5 })
      //   return false
      // }
      return true
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 60px 15px 0 15px;
  background: #ffffff;

  .content {
    .form {
      .lable {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        margin: 20px 0 10px 0;
      }

      .upImg {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .up_item {
          text-align: center;
          width: 50%;

          .up_img {
            width: 165px;
            height: 100px;
            border-radius: 5px 5px 5px 5px;
            border: 1px solid #EAEAEA;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .up_name {
            margin: 10px 0 20px 0;
            font-weight: 500;
            font-size: 15px;
            color: #333333;
          }
        }

      }

      .select_btn {
        margin-right: 10px;
      }

      .van-button {
        height: 37px;
        margin-bottom: 10px;
        margin-top: 10px;
      }

      .tips {
        text-align: center;
        font-size: 17px;
        font-weight: bold;
        color: #C92B2E;
      }

      .upbtn {
        margin-top: 30px;
        text-align: center;
        font-size: 15px;
        color: #C92B2E;
      }
    }
  }
}

.vue-country-popover-container {
  width: 100%;
}

::v-deep .van-field__value {
  overflow: visible;
  display: grid;
}

::v-deep .van-button__content {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

.van-cell {
  background: #F2F2F2;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
}

::v-deep .van-field__right-icon {
  margin: -10px 8px;
  padding: 0px;
  line-height: normal;
}
</style>
